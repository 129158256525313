* {
  box-sizing: border-box;
}
body {
  font-family: Arial, Helvetica, Sans-serif;
  background: #fff;
  padding: 0;
  margin: 0;
}
header {
  text-align: left;
}
.wrapper {
  clear: both;
  margin: 5px 0;
  width: 100%;
  float: left;
}

.loader {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.errorcontainer {
  position: relative;
  margin: 100px auto;
  padding-left: 100px;
  max-width: 600px;
}

.errorcontainer h1 {
  text-align: left;
  text-transform: uppercase;
  margin: 0;
}

.errorcontainer .icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 4em;
}

h1 {
  max-width: 70%;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  font-size: 1.5em;
}
h2 {
  padding: 0px 10px 0 0;
  margin: 15px 0 5px;
}
h2 i {
  padding-right: 15px;
}
a {
  color: #e74c3c;
  text-decoration: none;
}
a:hover {
  text-decoration: normal;
}

/* Tabs*/
#tabs {
  max-width: 70%;
  list-style: none;
  margin: 10px 0 -1px 0;
  padding: 0;
  float: left;
  width: 100%;
  float: left;
  clear: both;
}
#tabs li {
  float: left;
  margin: 0 0 0 10px;
  display: block;
  padding: 2px 15px;
  border-top: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  border-left: 1px solid #dfdfdf;
  background-color: #eee;
  border-top-left-radius: 2px;
  border-top-right-radius: 5px;
  font-size: 0.8em;
  padding: 2px 10px 1px 13px;
  line-height: 2em;
  cursor: pointer;
}
#tabs li.active {
  background-color: #fff;
  font-weight: bold;
  position: relative;
  z-index: 1;
}
#tabs i {
  padding-right: 8px;
}

/* Tool navigation */
nav {
  clear: both;
}
.rightNav {
  position: absolute;
  top: 5px;
  right: 15px;
}
.monthnav {
  font-size: 2em;
  color: #000;
}
.monthnav.left {
  float: left;
}
.monthnav.right {
  float: right;
}
#searchfield {
  float: right;
  text-align: right;
  max-width: 30%;
  margin-top: -60px;
  position: relative;
}
#toolfield {
  float: right;
  max-width: 30%;
  margin: -4px 0 5px;
}
#categorylist {
  float: left;
  list-style: none;
  margin: 6px 5px;
  padding: 0;
  max-width: 70%;
  font-size: 0.8em;
  font-style: italic;
}
#categorylist li {
  float: left;
  padding: 0 10px 0 0;
}
#categorylist li span,
.catcolor {
  display: block;
  width: 18px;
  height: 14px;
  border-radius: 2px;
  margin: -1px 5px 0 0;
  float: left;
  border: 1px solid #fff;
}

/* Calendar */
#calendar-wrap {
  padding: 10px;
}
#calendar {
  width: 100%;
  display: table;
}
#calendar a {
  color: #8e352e;
  text-decoration: none;
}
#calendar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: table-row;
}
#calendar li {
  width: 14.342%;
  box-sizing: border-box;
  border: 0.5px solid #e1e1e1;
  margin-right: -1px;
  margin-bottom: -1px;
  display: table-cell;
  vertical-align: top;
}
#calendar .day.today {
  background: rgba(25, 0, 250, 0.05);
}
#calendar .day .event.cancelled {
  background-color: #ded6d4 !important;
  border-color: #ded6d4 !important;
  color: #000 !important;
}
#calendar .day .event.cancelled .title {
  text-decoration: line-through !important;
}
#calendar .day .unconfirmed {
  border-style: dashed !important;
  border-color: #fff !important;
}
#calendar .day .icon {
  margin: 0 5px;
}
#calendar .day .icon i {
  padding: 0 2px;
}
#calendar ul.weekdays {
  background: #7e7e7e;
  width: 100%;
}
#calendar ul.weekdays li {
  text-align: center;
  font-weight: 700;
  border: none !important;
  padding: 8px 6px;
  color: #fff;
  font-size: 13px;
}
#calendar .days li {
  min-height: 80px;
}
#calendar .date {
  text-align: right;
  margin-bottom: 5px;
  padding: 5px;
  color: #aaa;
  width: 100%;
  box-sizing: border-box;
}
#calendar .add:hover {
  cursor: pointer;
}
#calendar .add:hover:before,
#calendar .add:active:before {
  font-family: "Material Icons";
  content: "\e145";
  float: left;
  font-weight: bolder;
  font-size: 1.1em;
  -webkit-text-stroke: 1px;
  text-align: center;
}
#calendar .event {
  clear: both;
  display: block;
  font-size: 13px;
  padding: 5px 7px;
  margin-bottom: 5px;
  line-height: 14px;
  background: #54b78e;
  border: 1px solid #fff;
  color: #ddd;
  text-decoration: none;
  cursor: pointer;
}
#calendar .event-desc {
  color: #fff;
  margin: 3px 0 7px;
  text-decoration: none;
  pointer-events: none;
}
#calendar .event-time {
  pointer-events: none;
}
#calendar .prev-month,
#calendar .next-month {
  background: #f1f1f1;
  color: #666;
}

/* Table lists */
table.list {
  width: 100%;
  clear: both;
  border-collapse: collapse;
  font-size: 0.9em;
  color: #555;
}
table.list a {
  color: #000;
}
table.list tr {
  border-bottom: 1px solid #e1e1e1;
}
table.list tr:nth-child(even) td {
  background: #f5f5f5;
}
table.list td {
  padding: 8px 10px;
  vertical-align: top;
}
table.list th {
  padding: 4px 10px;
  background: #7e7e7e;
  color: #fff;
  font-weight: bold;
  text-align: left;
}
table.list tr .title {
  cursor: pointer;
}
table.list .desc {
  width: 80%;
}

table.list .icons {
  padding: 0 0 0 5px;
}

table.list .status {
  color: #888;
}

table.list .icons i {
  padding: 0 2px;
}

/* Footer navigation */
#footnav {
  margin: 10px 0 0 0;
  list-style: none;
  padding: 0;
  clear: both;
  float: right;
  font-size: 0.8em;
}
#footnav li {
  float: left;
  padding: 5px 10px;
}
#footnav a {
  color: #000;
}
#footnav a i {
  padding-right: 5px;
  color: #e74c3c;
}

/* Input fields */
.tabcontent {
  display: none;
  width: 100%;
  float: left;
  padding: 10px 0;
  border-top: 1px solid #e1e1e1;
}
.tabcontent.active {
  display: inline;
}
.inputgroup {
  width: 100%;
  clear: both;
  padding: 2px 0;
}
.inputgroup label {
  font-size: 0.9em;
  display: inline-block;
  width: 20%;
  float: left;
  padding: 5px 0;
}
.inputgroup div.fieldwrap {
  width: 80%;
  float: right;
}
input,
select,
textarea {
  -webkit-appearance: none;
  float: left;
  height: 30px;
  display: block;
  width: 100%;
  padding: 6px 10px;
  color: #333;
  border: 1px solid #ededed;
  border-radius: 3px;
  box-sizing: border-box;
}
textarea {
  height: 100px;
}
select.day,
select.year,
input.time {
  width: 60px;
  float: left;
  margin: 0 2px 0 0;
}
select.month {
  width: 100px;
  float: left;
  margin: 0 2px 0 0;
}
button {
  background: #eee;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  padding: 6px 18px 6px 15px;
}
button i {
  padding-right: 12px;
}
.buttonwrap {
  margin: 20px 10px 20px 0;
  width: 100%;
  float: left;
}
.required label:after {
  content: "*";
  color: #e74c3c;
  padding-left: 2px;
}

.nocontent {
  width: 100%;
  display: block;
  clear: both;
  border-top: 1px solid #e1e1e1;
  padding-top: 15px;
  margin-top: 10px;
  float: left;
}

/* Make responsive */
@media only screen and (max-width: 700px) {
  #calendarwrap {
    padding: 0;
  }
  #calendar .weekdays,
  #calendar .other-month {
    display: none;
  }
  #calendar {
    display: inline;
  }
  #calendar ul {
    display: inline;
  }
  #calendar li {
    height: auto !important;
    border: 1px solid #ededed;
    width: 100%;
    padding: 10px;
    margin-bottom: -1px;
    display: block;
  }
  #calendar .date {
    float: none;
  }
  #calendar .days li {
    min-height: 0;
  }
  #tabs {
    max-width: 100% !important;
    float: left !important;
  }
  #toolfield,
  #searchfield,
  #categorylist,
  #monthnav {
    display: none;
  }
  #eventform .inputgroup label {
    clear: both;
    width: 100%;
  }
  #eventform .inputgroup div.fieldwrap {
    clear: both;
    width: 100%;
    float: left;
  }
  .caltitle {
    display: none;
  }
  .prev-month,
  .next-month {
    display: none !important;
  }
}

.tabWrapper {
  border-bottom: 1px solid #dfdfdf;
}
.tabWrapper.main {
  margin-bottom: 24px;
}

.headtext {
  margin: 0 0 10px 0;
  text-align: left;
  display: block;
}

.activeStateIcon {
  margin: -5px 0;
}

/* Event dialog tabs */
section {
  display: none;
  padding: 20px 0 0;
  border-top: 1px solid #ddd;
}

#fieldgroup-titles > input {
  display: none;
}

#fieldgroup-titles > label {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #bbb;
  border: 1px solid transparent;
}

#fieldgroup-titles > label:hover {
  color: #888;
  cursor: pointer;
}

#fieldgroup-titles > input:checked + label {
  color: #555;
  border: 1px solid #ddd;
  border-top: 2px solid orange;
  border-bottom: 1px solid #fff;
}

.previewpopup {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  font-size: 0.8em;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  overflow: hidden;
}

.previewpopup table {
  width: 100%;
  border-collapse: collapse;
}

.previewpopup .head {
  width: 100%;
  text-align: center;
  background-color: #e1e1e1;
  padding: 8px 0 6px;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

.previewpopup td,
.previewpopup th {
  padding: 3px 15px;
  vertical-align: top;
  text-align: left;
}

.previewpopup .confirmed {
  color: #3b7230;
  font-weight: bold;
}
.previewpopup .unconfirmed {
  color: #bf3f3f;
  font-weight: bold;
}
.previewpopup .cancelled {
  color: #919191;
  font-weight: bold;
}

.formcontrol {
  margin: 10px 0 !important;
}

.print table {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.print table th {
  padding: 5px;
  font-weight: bold;
  text-align: left !important;
  width: 25%;
  vertical-align: top;
}

.print table td {
  padding: 5px;
  text-align: left !important;
  vertical-align: top;
}

#emailForm label {
  float: left;
  display: block;
  width: 100%;
  font-weight: bold;
  margin: 15px 0 5px;
}

#emailForm input {
  float: left;
  display: block;
  width: 100%;
}

#emailForm textarea {
  float: left;
  display: block;
  width: 100%;
  min-height: 400px;
}

.tabcontainer {
  display: none;
}
.tabcontainer.active {
  margin-top: 15px;
  display: block;
}

.adminfooter {
  margin-top: 10px !important;
  font-size: 0.9em !important;
}

.paperhead {
  margin: 0;
  display: inline;
}

.paperheadwrap {
  clear: both;
  overflow: hidden;
  padding-bottom: 10px;
}

.paperwrap {
  margin: 10px 0;
}

.textareawrap {
  border: 1px solid #ddd;
  padding: 0 16px;
  margin: 10px 0;
  border-radius: 2px;
}
